import {styled} from '@mui/material/styles';
import {FC, ReactNode} from 'react';
import {theme} from '../../../lib/theme';
import {Div} from '../../styledComponents/Div';
import {useMatches} from '../../utils/CommonUtils';
import {EgdLogo} from './EgdLogo';

const DivWrapper = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.common.white,
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(4)} 0`,
}));
const DivChildren = styled('div')(() => ({
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    overflowY: 'auto',
}));

type Props = {
    readonly children: ReactNode;
};

/**
 * Wrapper komponenta obsahující pozadí aplikace
 */
export const LandingBackground: FC<Props> = ({children}) => {
    const matches = useMatches();
    return (
        <DivWrapper>
            <DivChildren role="main">
                <Div sx={{display: 'flex', justifyContent: 'center', ml: 2, mr: 2}}>
                    <EgdLogo width={matches ? 100 : 120} height={matches ? 70 : 100} pathFill={theme.palette.error.main} />
                </Div>
                {children}
            </DivChildren>
        </DivWrapper>
    );
};
