import {Maybe, ProduktStav, Skupina, Ucet, UcetTyp} from '@eon.cz/gemini11-graphql';
import {SettingsInputComponentOutlined, SummarizeOutlined} from '@mui/icons-material';
import {useMediaQuery} from '@mui/material';
import {ReactNode, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {BackendEndpoints} from '../../../server/BackendEndpoints';
import store from '../../lib/store';
import {NotificationType} from '../../models/notification/NotificationModel';
import {PageRoute, RoutePath} from '../PageRouteModel';
import {NotificationsActionCreator} from '../components/notifications/actions/NotificationsActions';
import {ElectricityIcon} from '../icons/ElectricityIcon';
import {useMatches} from '../utils/CommonUtils';

export type AppLeftMenuModel = {
    readonly label: string;
    readonly description?: string;
    readonly route?: string;
    readonly icon: string | ReactNode;
    readonly afterFragment?: boolean;
    readonly children?: AppLeftMenuModel[];
    readonly allowInLimitedMode?: boolean;
    readonly canShow?: Maybe<boolean>;
};

/**
 * "If the account is an admin, return true for all routes, otherwise return true for only the two
 * routes that are not admin routes."
 *
 * The function is a bit more complicated than that, but that's the gist of it
 * @param {Ucet} account - Ucet - this is the account object that is passed to the function.
 * @returns A function that takes a PageRoute and returns a boolean.
 */
const filterRoute =
    (account: Ucet | undefined) =>
    (f: PageRoute): boolean => {
        if (account) {
            if (account.typ === UcetTyp.SPRAVCE) {
                return f === PageRoute.NASTAVENI_PARAMETRU || f === PageRoute.OBSLUHA_PRODUKTU || f === PageRoute.PREHLED_SPOTREBY;
            }
            if (account.typ === UcetTyp.ZMOCNENEC || Array.isArray(account.pristupy)) {
                return f === PageRoute.NASTAVENI_PARAMETRU || f === PageRoute.PREHLED_SPOTREBY;
            }
        }
        return false;
    };

/**
 * It takes an account and returns an array of page routes that the account is allowed to access
 * @param {Ucet} account - Ucet - this is the account object that is passed to the function.
 */
export const getPageRoutes = (account: Ucet | undefined): PageRoute[] =>
    Object.keys(PageRoute)
        .map((m) => PageRoute[m as RoutePath])
        .filter(filterRoute(account));

/* A function that takes three parameters and returns an array of AppLeftMenuModel. */
export const getMenuItems = (account: Ucet | undefined, skupina: Skupina | undefined | null, canShow: Maybe<boolean> | undefined): AppLeftMenuModel[] => {
    if ((!account || !skupina) && account?.pristupy?.length === 0) {
        return [];
    }
    return [
        {label: 'menu.prehled.spotreby', route: PageRoute.PREHLED_SPOTREBY, icon: <ElectricityIcon />, afterFragment: false, canShow},
        {
            label: 'menu.nastaveni.parametru',
            route: PageRoute.NASTAVENI_PARAMETRU,
            icon: <SettingsInputComponentOutlined />,
            afterFragment: false,
            canShow,
        },
        {
            label: 'menu.obsluha.produktu',
            route: PageRoute.OBSLUHA_PRODUKTU,
            icon: <SummarizeOutlined />,
            afterFragment: true,
            canShow: account?.typ !== UcetTyp.ZMOCNENEC,
        },
    ]
        .filter(({canShow}) => canShow)
        .filter((f) => (!f.route && account) || getPageRoutes(account).indexOf(f.route) !== -1);
};

/**
 * Get pathname (URI) for given route. Komodita can be omitted when it is not necessary.
 * The whole point of this function is to prepend komodity or not depending on
 * configuration. This is core of the function.
 *
 * @param {PageRouteModel} route - Route to get path
 * @param {string} postfix - Postfix to append after the route. MUST start with '/' if not empty!
 * @param {Komodita} komodita - Komodita when needed. If needed and not provided, exception is thrown.
 */
const getParsePathname = (route: PageRoute | undefined, postfix?: string) => {
    if (typeof postfix === 'string' && postfix.length > 0 && postfix[0] !== '/') {
        throw Error(`Postfix ${postfix} not starting with '/'!`);
    }

    // Just a simple path
    return `${route}${typeof postfix === 'string' ? postfix : ''}`;
};

/**
 * Get path name from route and postfix, and convert empty string to slash (= go to root) since empty
 * string does not work that way.
 * @param {PageRoute} route - PageRoute
 * @param {string} [postfix] - string = ''
 * @returns A function that takes in a route and a postfix and returns a pathname.
 */
export const getPathname = (route: PageRoute, postfix?: string) => {
    // Get path name
    const pathName = getParsePathname(route, postfix);

    // Convert empty string to slash (= go to root) since empty string does not work that way
    return pathName === '' ? '/' : pathName;
};

/**
 * "If the product is active, finished, or has a change in progress, then it has been agreed upon."
 *
 * The function is used in the following way:
 * @param {ProduktStav | undefined} stav - ProduktStav | undefined
 */
export const hasZasmluvneno = (stav: ProduktStav | undefined) =>
    [ProduktStav.PRODUKT_UKONCEN, ProduktStav.PRODUKT_AKTIVNI_DORESENY, ProduktStav.PRODUKT_AKTIVNI_PROBIHA_ZMENA].includes(stav ?? ProduktStav.PRODUKT_NENI);

/**
 * It takes a disposition of downloaded file and returns decoded file name
 * @param {string} disposition - The Content-Disposition header from the response.
 * @returns A function that takes a string and returns a string or null.
 */
const getFileName = (disposition: string): string | null => {
    const utf8FilenameRegex = /filename\*=UTF-8''([\w%\-\.]+)(?:; ?|$)/i;
    const asciiFilenameRegex = /^filename=(["']?)(.*?[^\\])\1(?:; ?|$)/i;

    if (utf8FilenameRegex.test(disposition)) {
        return decodeURIComponent(utf8FilenameRegex?.exec(disposition)?.[1] ?? '');
    }
    // prevent ReDos attacks by anchoring the ascii regex to string start and
    //  slicing off everything before 'filename='
    const filenameStart = disposition.toLowerCase().indexOf('filename=');
    if (filenameStart >= 0) {
        const partialDisposition = disposition.slice(filenameStart);
        const matches = asciiFilenameRegex.exec(partialDisposition);
        if (matches?.[2]) {
            return decodeURIComponent(matches[2]);
        }
    }
    return null;
};

/**
 * It downloads a file from the backend
 * @param {string | null | undefined} id - string | null | undefined - the id of the attachment
 * @param setOpenLoading - (open: boolean) => void - this is a function that sets the loading state of
 * the component.
 */
export const downloadFileFromDMS = (id: string | null | undefined, setOpenLoading: (open: boolean) => void) => {
    const {addNotification} = NotificationsActionCreator(store.dispatch);
    setOpenLoading(true);
    fetch(`${window.location.origin}/api/${BackendEndpoints.DOWNLOAD}/${id}`)
        .then((response) => {
            switch (response.status) {
                case 200: // BE find a file
                    response.blob().then((blob) => {
                        const fileName = getFileName(response?.headers?.get('content-disposition') ?? '');
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.setAttribute('href', url);
                        a.setAttribute('download', fileName ?? '');
                        a.click();
                    });
                    setOpenLoading(false);
                    break;
                case 413:
                    addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.priloha.download" />});
                    break;
                default:
                    addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.priloha.download" />});
                    setOpenLoading(false);
            }
        })
        .catch(() => {
            setOpenLoading(false);
            addNotification({type: NotificationType.ERROR, text: <FormattedMessage id="error.priloha.download" />});
        });
};

const getOrientation = () => window.screen.orientation.type;

export const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState(getOrientation());

    const updateOrientation = () => {
        setOrientation(getOrientation());
    };

    useEffect(() => {
        window.addEventListener('orientationchange', updateOrientation);
        return () => {
            window.removeEventListener('orientationchange', updateOrientation);
        };
    }, []);

    return orientation;
};

/**
 * The useTablet function returns true if the screen width is between 600px and 1200px.
 * @returns The function `useTablet` is returning a boolean value that is true if the screen width is
 * between 600px and 1200px (inclusive) and false otherwise.
 */
export const useTablet = () => {
    const minWidth600 = useMediaQuery('(min-width:600px)');
    const maxWidth1200 = useMatches('1200px');
    return maxWidth1200 && minWidth600;
};
